<template>
<div class="charts" ref="myCharts" :class="{bigScreen:!isSmallScreen, smallScreen:isSmallScreen}" v-loading="loading">
  <div class="main">
    <div class="header">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" ></el-tab-pane>
        <el-tab-pane label="机构自评"></el-tab-pane>
        <el-tab-pane label="监管他评"></el-tab-pane>
      </el-tabs>
      <div class="select">
        评估年份：
        <el-select v-model="currentYear" placeholder="请选择" @change="getYear()">
          <el-option
            v-for="item in yearList"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="content">
      <div class="content-chart margin-bottom" :class="{width100:isSmallScreen}">
        <div class="content-chart" >
          <div class="chart-item total-data" v-if="centerData.top_data">
            <el-radio-group v-model="totalRadio" size="mini" class="radio" @change="init()">
              <el-radio-button label="1">按机构数</el-radio-button>
              <el-radio-button label="2">按次数</el-radio-button>
            </el-radio-group>
            <div v-show="activeName == 0">
              <p>托育机构数量<span>{{ centerData.top_data.org_count_all }}</span></p>
              <p>自评提交{{ totalRadio == '1' ? '机构数' : '次数' }}<span>{{centerData.top_data.inspect_count_all}}</span></p>
              <p>监管他评{{ totalRadio == '1' ? '机构数' : '次数' }}<span>{{centerData.top_data.inspect_count_all_ta}}</span></p>
              <p v-show="totalRadio == 1">自评完成率<span>{{centerData.top_data.inspect_ratio_all}}</span></p>
            </div>
            <div v-show="activeName == 1">
              <p>托育机构数量<span>{{ centerData.top_data.org_count_all }}</span></p>
              <p>自评提交{{ totalRadio == '1' ? '机构数' : '次数' }}<span>{{ centerData.top_data.inspect_count_all }}</span></p>
              <p>{{ totalRadio == '1' ? '自评评审完成机构数' : '自评评审完成次数' }}<span>{{ centerData.top_data.inspect_count_all_wancheng }}</span></p>
              <p v-show="activeName == 1 && totalRadio == 1">自评完成率<span>{{centerData.top_data.inspect_ratio_all}}</span></p>
            </div>
            <div v-show="activeName == 2">
              <p>监管他评{{ totalRadio == '1' ? '机构数' : '次数' }}<span>{{ centerData.top_data.inspect_count_all_ta }}</span></p>
              <p>市级他评{{ totalRadio == '1' ? '机构数' : '次数' }}<span>{{centerData.top_data.shi_count_all}}</span></p>
              <p>省级他评{{ totalRadio == '1' ? '机构数' : '次数' }}<span>{{centerData.top_data.sheng_count_all}}</span></p>
              <p>国家督评{{ totalRadio == '1' ? '机构数' : '次数' }}<span>{{centerData.top_data.guojia_count_all}}</span></p>
            </div>
          </div>
          <div class="chart-item today-data" v-if="centerData.today_data">
            <h4>{{ activeName == 2 ? "今日他评情况" : "今日自评情况" }}</h4>
            <div class="number">
              <p>
                {{activeName == 2 ? "今日他评评估次数" : "今日自评评估次数"}}
                
                <span>{{centerData.today_data.today_count || 0}}</span></p>
              <p>
                环比昨日评估次数
                <span>{{centerData.today_data.yesterday_ratio}}% 
                  <i class="el-icon-top" style="color:red;" v-if="centerData.today_data.yesterday_ratio>=0"></i>
                  <i class="el-icon-bottom" style="color:#52C41A;" v-if="centerData.today_data.yesterday_ratio<0"></i>
              </span>
               
              </p>
            </div>
            <!-- <p class="rate"><span :style="{width:centerData.top_data.inspect_ratio_all}"></span></p> -->
            <div class="average-number">
              <p>日均评估数量：<span>{{centerData.today_data.day_average}}</span></p>
            </div>
          </div>
        </div>
        
        <div class="chart-item" style="height: 248px">
          <h4> {{activeName !=2 ? "自评评审":"他评"}}等级分析</h4>
          <el-select @change="init()" v-model="levelSelect" v-show="activeName==2"  placeholder="请选择" size="mini" class="select">
            <el-option
              v-for="item in selectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <div class="chart-level">
            <div>
              <p class="label" v-if="echartPieTotal && centerData">A级<span>{{ (centerData.results_arr.results_4 / echartPieTotal * 100).toFixed(2)}}%</span></p>
              <p class="label" v-else>A级<span>0.00%</span></p>
              <div id="chart-levelA"></div>
              优秀：{{centerData?.results_arr?.results_4}}
            </div>
            <div>
              <p class="label" v-if="echartPieTotal && centerData">B级<span>{{ (centerData.results_arr.results_3 / echartPieTotal * 100).toFixed(2)}}%</span></p>
              <p class="label" v-else>B级<span>0.00%</span></p>
              <div id="chart-levelB"></div>
              合格：{{centerData?.results_arr?.results_3}}
            </div>
            <div>
              <p class="label" v-if="echartPieTotal && centerData">C级<span>{{ (centerData.results_arr.results_2 / echartPieTotal * 100).toFixed(2)}}%</span></p>
              <p class="label" v-else>C级<span>0.00%</span></p>
              <div id="chart-levelC"></div>
              不合格：{{centerData?.results_arr?.results_2}}
            </div>
            <div>
              <p class="label" v-if="echartPieTotal && centerData">D级<span>{{ (centerData.results_arr.results_1 / echartPieTotal * 100).toFixed(2)}}%</span></p>
              <p class="label" v-else>D级<span>0.00%</span></p>
              <div id="chart-levelD"></div>
              需要重大改进：{{centerData?.results_arr?.results_1}}
            </div>
          </div>
        </div>
      </div>
      <div class="content-chart margin-bottom"  :class="{width100:isSmallScreen}">
        <div>
          <div id="map" class=" margin-bottom"></div>
          <div class="chart-item intro big">
            <h4>评估内容概况</h4>
            <div class="intro-list" v-if="rightData1.content_summary">
              <div class="intro-item" style="background-color:#CDDDFD;margin-right: 24px;" v-show="activeName != 2">
                <p class="intro-title">
                  <span><img src="@/assets/indexNew/机构评估@2x.png"/></span>机构自评
                </p>
                <div class="intro-data">
                  <p style="min-width:120px">平均用时<br/><b>{{rightData1.content_summary.average_total_time || '00:00:00'}}</b></p>
                  <p>平均得分<br/><b>{{rightData1.content_summary.average_score}}</b><span>分</span></p>
                </div>
              </div>
              <div class="intro-item" style="background-color:#CDF3E4;" v-if="activeName != 2">
                <p class="intro-title">
                  <span><img src="@/assets/indexNew/督导评审@2x.png"/></span>自评评审
                </p>
                <div class="intro-data">
                  <p style="min-width:120px">平均用时<br/><b>{{rightData1.content_summary.average_examine_total_time || '00:00:00'}}</b></p>
                  <p>平均得分<br/><b>{{rightData1.content_summary.average_examine_score}}</b><span>分</span></p>
                </div>
              </div>
              <div class="intro-item"  style="background-color:#CED4DE; margin-left: 24px;" v-if="activeName == 0">
                <p class="intro-title">
                  <span><img src="@/assets/indexNew/国家级督评价@2x.png"/></span>监管他评
                </p>
                <div class="intro-data">
                  <p style="min-width:120px">平均用时<br/><b>{{rightData1.content_summary.average_total_time_ta || '00:00:00'}}</b></p>
                  <p>平均得分<br/><b>{{rightData1.content_summary.average_score_ta}}</b><span>分</span></p>
                </div>
              </div>
              <div class="intro-item" style="background-color:#CDDDFD;margin-right: 24px;" v-if="activeName == 2">
                <p class="intro-title">
                  <span><img src="@/assets/indexNew/机构评估@2x.png"/></span>市级他评
                </p>
                <div class="intro-data">
                  <p style="min-width:120px">平均用时<br/><b>{{rightData1.content_summary.average_total_time_shi || '00:00:00'}}</b></p>
                  <p>平均得分<br/><b>{{rightData1.content_summary.average_score_shi}}</b><span>分</span></p>
                </div>
              </div>
              <div class="intro-item" style="background-color:#CDF3E4;" v-if="activeName == 2">
                <p class="intro-title">
                  <span><img src="@/assets/indexNew/督导评审@2x.png"/></span>省级他评
                </p>
                <div class="intro-data">
                  <p style="min-width:120px">平均用时<br/><b>{{rightData1.content_summary.average_total_time_sheng || '00:00:00'}}</b></p>
                  <p>平均得分<br/><b>{{rightData1.content_summary.average_score_sheng}}</b><span>分</span></p>
                </div>
              </div>
              <div class="intro-item" v-if="activeName == 2" style="background-color:#CED4DE; margin-left: 24px;">
                <p class="intro-title">
                  <span><img src="@/assets/indexNew/国家级督评价@2x.png"/></span>国家级督评
                </p>
                <div class="intro-data">
                  <p style="min-width:120px">平均用时<br/><b>{{rightData1.content_summary.average_total_time_guojia || '00:00:00'}}</b></p>
                  <p>平均得分<br/><b>{{rightData1.content_summary.average_score_guojia}}</b><span>分</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="content-chart margin-bottom">
            <div class="chart-item region">
              <h4>{{ activeName ==2 ? '他评' : '自评评审'}}区域分析</h4>
              <el-select v-model="regionSelect" @change="initLeft2()" v-show="activeName==2"  size="mini" placeholder="请选择" class="select">
                <el-option
                  v-for="item in selectOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <!-- <el-radio-group v-model="regionType" size="small">
                <el-radio-button label="图"></el-radio-button>
                <el-radio-button label="表"></el-radio-button>
              </el-radio-group> -->
              <!-- <div class="region-tu" v-show="regionType=='图'">
                <p>
                  <span v-for="(item,index) in regionList.slice(0,6)" :class="'regionBg'+index" :style="{height:item.rate}"></span>
                </p>
                <ul>
                  <li v-for="(item,index) in regionList.slice(0,6)" >
                    <span :class="'regionBg'+index"></span>
                    <p class="region-name">{{ item.name > 4 ? item.name.slice(0,4)+'...' : item.name}}</p>
                    <p class="region-rate">{{ item.rate }}</p>
                    <p class="region-value">{{ item.value }}所</p>
                  </li>
                </ul>
              </div> -->
              <div  class="region-table">
                <el-table
                  :data="regionTable"
                  style="width: 100%">
                  <el-table-column
                    v-if="role == 1 || role == 2"
                    prop="name"
                    label="省份"
                  >
                    <template slot-scope="scope">
                      <el-tooltip class="item" effect="dark" :content="scope.row.name" placement="top-start">
                        <p class="ellispe" style="color: #1890FF;">{{ scope.row.name  }}</p>
                      </el-tooltip>
                      <!-- <span style="color: #1890FF;">{{ scope.row.name }}</span> -->
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-if="role == 6"
                    prop="name"
                    label="地市"
                  >
                    <template slot-scope="scope">
                      <el-tooltip class="item" effect="dark" :content="scope.row.name" placement="top-start">
                        <p class="ellispe" style="color: #1890FF;">{{ scope.row.name  }}</p>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-if="role == 3"
                    prop="name"
                    label="区县"
                  >
                    <template slot-scope="scope">
                      <el-tooltip class="item" effect="dark" :content="scope.row.name" placement="top-start">
                        <p class="ellispe" style="color: #1890FF;">{{ scope.row.name  }}</p>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="value"
                    sortable
                    label="自评评审机构数量"
                  >
                  <template slot="header" slot-scope="scope">
                    <p  v-if="activeName == 2" style="line-height: 20px; display: inline-block;text-align: center;">他评完成<br/>机构数量</p>
                    <p style="line-height: 20px; display: inline-block;text-align: center;" v-else >自评评审<br/>机构数量</p>
                  </template>
                  </el-table-column>
                  <el-table-column
                    width="80"
                    prop="rate"
                    label="占比"
                  >
                  </el-table-column>
                </el-table>
                <el-pagination
                  @current-change="handleCurrentChange"
                  :current-page.sync="regionPage"
                  :page-size="5"
                  layout="prev, pager, next"
                  :total="regionList.length">
                </el-pagination>
              </div>
            </div>
            <div class="chart-item trend">
              <h4>{{ activeName == 2 ? '他评' : '自评'}}趋势分析</h4>
              <div class="top">
                <el-date-picker class="picker" 
                  v-show="r1tab==1"
                v-model="datetime[1]" size="mini" 
                :picker-options="pickerOptions"
                type="daterange" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" @change="initLeft1">
                </el-date-picker>
                <el-date-picker class="picker" 
                v-show="r1tab==2"
                v-model="datetime[2]" size="mini" 
                :picker-options="pickerOptions"
                type="daterange" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" @change="initLeft1">
                </el-date-picker>
                <el-date-picker class="picker" 
                v-show="r1tab==3"
                v-model="datetime[3]" size="mini" 
                :picker-options="pickerOptions"
                type="daterange" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" @change="initLeft1">
                </el-date-picker>
                <el-radio-group size="mini" v-model="r1tab" @change="initLeft1">
                  <el-radio-button label="1">日</el-radio-button>
                  <el-radio-button label="2">周</el-radio-button>
                  <el-radio-button label="3">月</el-radio-button>
                </el-radio-group>
              </div>
              <div id="chart1" class="chart"></div>
            </div>
          </div>
          <div class="chart-item news" id="13"  ref="l3">
            <h4>评估实时动态</h4>

              <el-table
              class="realTableHeader"
                  :data="leftTableData"
                  :height="leftTableData.length > 10? 520 : ''"
                  style="width: 100%">
                  <el-table-column
                        prop="myIndex"
                        label="序号"
                        width="50"
                      >
                  </el-table-column>
                  <el-table-column
                        prop="organization_name"
                        label="机构名称"
                      >
                      <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" :content="scope.row.organization_name" placement="top-start">
                          <p class="ellispe">{{ scope.row.organization_name  }}</p>
                        </el-tooltip>
                      </template>
                      </el-table-column>
                      <el-table-column
                        prop="province_city_area"
                        label="省市区"
                      >
                        <template slot-scope="scope">
                          <el-tooltip class="item" effect="dark" :content="scope.row.province_city_area" placement="top-start">
                            <p class="ellispe">{{ scope.row.province_city_area  }}</p>
                          </el-tooltip>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="value"
                        label="评估等级"
                        width="180"
                      >
                        <template slot-scope="scope">
                          <template v-if="scope.row.type != 1">
                            <p v-if="scope.row.results == 4"><span style="backgroundColor:#3AA1FF"></span>A级（优秀）</p>
                            <p v-if="scope.row.results == 3"><span style="backgroundColor:#2FC25B"></span>B级（合格）</p>
                            <p v-if="scope.row.results == 2"><span style="backgroundColor:#FBD437"></span>C级（不合格）</p>
                            <p v-if="scope.row.results == 1"><span style="backgroundColor:#F5222D"></span>D级（需要重大改进）</p>
                          </template>
                          <template v-if="scope.row.type == 1">
                            <p v-if="scope.row.examine_results == 4"><span style="backgroundColor:#3AA1FF"></span>A级（优秀）</p>
                            <p v-if="scope.row.examine_results == 3"><span style="backgroundColor:#2FC25B"></span>B级（合格）</p>
                            <p v-if="scope.row.examine_results == 2"><span style="backgroundColor:#FBD437"></span>C级（不合格）</p>
                            <p v-if="scope.row.examine_results == 1"><span style="backgroundColor:#F5222D"></span>D级（需要重大改进）</p>
                          </template>
                          
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="rate"
                        label="评估类型"
                        width="100"
                      >
                      <!-- 1234分别对应机构自评，市级他评，国家督评，省级他评 -->
                        <template slot-scope="scope">
                          <p v-if="scope.row.type == 4">省级他评</p>
                          <p v-if="scope.row.type == 3">国家督评</p>
                          <p v-if="scope.row.type == 2">市级他评</p>
                          <p v-if="scope.row.type == 1">机构自评</p>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="examine_date"
                        label="评审时间"
                        width="140"
                      >
                      <template slot-scope="scope">
                        {{ scope.row.type == 1 ? scope.row.examine_date.slice(0,16) : scope.row.submit_date.slice(0,16) }}
                      </template>
                      </el-table-column>
                </el-table>
          </div>
        </div>
      </div>
      <!-- 小屏显示 -->
      <div class="chart-item intro small margin-bottom">
            <h4>评估内容概况</h4>
            <div class="intro-list" v-if="rightData1.content_summary">
              <div class="intro-item" style="background-color:#CDDDFD;margin-right: 24px;" v-show="activeName != 2">
                <p class="intro-title">
                  <span><img src="@/assets/indexNew/机构评估@2x.png"/></span>机构自评
                </p>
                <div class="intro-data">
                  <p>平均用时<br/><b>{{rightData1.content_summary.average_total_time || '00:00:00'}}</b></p>
                  <p>平均得分<br/><b>{{rightData1.content_summary.average_score}}</b><span>分</span></p>
                </div>
              </div>
              <div class="intro-item" style="background-color:#CDF3E4;" v-show="activeName != 2">
                <p class="intro-title">
                  <span><img src="@/assets/indexNew/督导评审@2x.png"/></span>自评评审
                </p>
                <div class="intro-data">
                  <p>平均用时<br/><b>{{rightData1.content_summary.average_examine_total_time || '00:00:00'}}</b></p>
                  <p>平均得分<br/><b>{{rightData1.content_summary.average_examine_score}}</b><span>分</span></p>
                </div>
              </div>
              <div class="intro-item"  style="background-color:#CED4DE; margin-left: 24px;" v-if="activeName == 0">
                <p class="intro-title">
                  <span><img src="@/assets/indexNew/国家级督评价@2x.png"/></span>监管他评
                </p>
                <div class="intro-data">
                  <p>平均用时<br/><b>{{rightData1.content_summary.average_examine_total_time || '00:00:00'}}</b></p>
                  <p>平均得分<br/><b>{{rightData1.content_summary.average_examine_score}}</b><span>分</span></p>
                </div>
              </div>
              <div class="intro-item" style="background-color:#CDDDFD;margin-right: 24px;" v-show="activeName == 2">
                <p class="intro-title">
                  <span><img src="@/assets/indexNew/机构评估@2x.png"/></span>市级他评
                </p>
                <div class="intro-data">
                  <p>平均用时<br/><b>{{rightData1.content_summary.average_total_time || '00:00:00'}}</b></p>
                  <p>平均得分<br/><b>{{rightData1.content_summary.average_score}}</b><span>分</span></p>
                </div>
              </div>
              <div class="intro-item" style="background-color:#CDF3E4;" v-show="activeName == 2">
                <p class="intro-title">
                  <span><img src="@/assets/indexNew/督导评审@2x.png"/></span>省级他评
                </p>
                <div class="intro-data">
                  <p>平均用时<br/><b>{{rightData1.content_summary.average_examine_total_time || '00:00:00'}}</b></p>
                  <p>平均得分<br/><b>{{rightData1.content_summary.average_examine_score}}</b><span>分</span></p>
                </div>
              </div>
              <div class="intro-item" v-if="activeName == 2" style="background-color:#CED4DE; margin-left: 24px;">
                <p class="intro-title">
                  <span><img src="@/assets/indexNew/国家级督评价@2x.png"/></span>国家级督评
                </p>
                <div class="intro-data">
                  <p>平均用时<br/><b>{{rightData1.content_summary.average_total_time || '00:00:00'}}</b></p>
                  <p>平均得分<br/><b>{{rightData1.content_summary.average_score}}</b><span>分</span></p>
                </div>
              </div>
            </div>
          </div>
      <div class="content-chart margin-bottom" :class="{width100:isSmallScreen}">
        <div class="chart-item seven-data">
          <h4>7大指标{{activeName == 2 ? '他评' : '自评'}}平均分分析</h4>
          <el-select v-model="sevenSelect" @change="getSeven()" v-show="activeName==2" size="mini" placeholder="请选择" class="select">
            <el-option
              v-for="item in selectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <ul>
            <li v-for="(item,index) of sevenData" :key="item.id">
              <img :src="sevenImg[index]"/>
              <p class="seven-name">
                {{item.name}}
                <span>满分：{{item.score*1}}</span>
              </p>
              <p class="seven-value" style="flex: 1">
                <span>平均得分</span>
                {{item.average_inspect_score*1}}
              </p>
              <p class="seven-value">
                <span>平均得分比重</span>
                {{item.score_ratio}}
              </p>
            </li>
          </ul>
        </div>
        <div class="content-chart">
          <div class="chart-item seven-time">
            <h4>7大指标{{activeName == 2 ? '他评' : '自评'}}用时分析</h4>
            <!-- timeSelect -->
            <el-select v-model="timeSelect"  @change="getSevenTime()" v-show="activeName==2"  placeholder="请选择" size="mini" class="select">
            <el-option
              v-for="item in selectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
            <ul>
              <li>
                <p>指标</p>
                <p>平均用时</p>
                <p>占比</p>
              </li>
              <li v-for="(item,index) of sevenTimeData">
                <p>{{item.name}}</p>
                <p>{{item.average_object_total_date || '00:00:00'}}</p>
                <p>{{item.total_time_ratio}}%</p>
              </li>
            </ul>
          </div>
          <div class="chart-item seven-rate" style="height: 400px">
            <h4>7大指标{{activeName == 2 ? '他评' : '自评'}}分值比重分析</h4>
            <!-- rateSelect -->
            <el-select v-model="rateSelect"  @change="getSevenRate()" v-show="activeName==2"  placeholder="请选择" size="mini" class="select">
              <el-option
                v-for="item in selectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <div id="r4"></div>
          </div>
        </div>
      </div>
      <div class="chart-item question-rand">
        <h4>标星不合格排行榜</h4>
        <div slot="reference" class="item"  v-for="(item,index) of questionRandList" :key="index" >
          <span>{{index+1}}</span>
          <p>{{item.name}}</p>
        </div>
        <!-- <p class="more" v-if="questionRandList.length" @click="questionRandMore">{{ questionFinished ? '全部加载完' : '加载更多'}}</p> -->
      </div>
    </div>
  </div>
</div>
</template>

<script>
import china from '@/assets/china_2.js'
import seven0 from '@/assets/indexNew/seven0.png'
import seven1 from '@/assets/indexNew/seven1.png'
import seven2 from '@/assets/indexNew/seven2.png'
import seven3 from '@/assets/indexNew/seven3.png'
import seven4 from '@/assets/indexNew/seven4.png'
import seven5 from '@/assets/indexNew/seven5.png'
import seven6 from '@/assets/indexNew/seven6.png'
let proviceMap = {}
china.features.map(e => {
  proviceMap[e.properties.id] = e.properties.name;
})
export default {
  name: "charts",
  data() {
   
    return {
      role: "",

      totalRadio:'1',

      selectOptions:[{
        value: '2',
        label: '市级他评'
      },{
        value: '4',
        label: '省级他评'
      },{
        value: '3',
        label: '国家督评'
      }],
      regionSelect: '2',
      levelSelect: '2',
      rateSelect: '2',
      timeSelect: '2',
      sevenSelect: '2',

      sevenData:{},
      sevenTimeData: {},
      sevenRateData:{},

      activeName: '0',
      yearList:[],
      currentYear:'2024',
      echartPieTotal: 0,
      bigScreen: false,

      regionType: '图',
      regionList:[],
      regionTable:[],
      regionPage: 1,
      sevenImg:[seven0,
      seven1,
      seven2,
      seven3,
      seven4,
      seven5,
      seven6,],

      questionRandPage: 1,
      questionRandSize: 10,
      questionFinished: false,
      questionRandList:[],

      chartA: '',
      chartB: '',
      chartC: '',
      chartD: '',

      r1tab: '1',
      today:'',
      weekAgo:'',
      week7Ago: '',
      month7Ago: '',
      datetime: {
        1:[this.weekAgo, this.today],
        2:[this.weekAgo, this.week7Ago],
        3:[this.weekAgo, this.month7Ago]
      },
      pickerOptions: {
       // 只能选择当前年，并当前年之后不可选
        disabledDate:(time)=> {
       
          let year = this.currentYear;
          return (
            time.getTime() > new Date(year + "-12-31").getTime() 
            || time.getTime() < new Date(year + "-01-01").getTime() - 86400000
            || time.getTime() > Date.now()
          );
        }
      },
      l1: '',
      l2: '',
      r2: '',
      map: '',
      centerData: '',
      rightData1: '',
      leftData1: '',
      leftData2: '',
      rightData2: '',
      city: '',
      color: ['#0079FE', '#1ABE6B', '#FFBA01'],
      isSmallScreen: '',
      time: '',
      timer: '',
      selectValue1: 0,
      selectText1: '',
      selectValue2: 0,
      selectText2: '',
      selectText3: '',
      color2: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452'],
      leftTablePage: 1,
      leftTableData: [],
      leftTableTimer: null,
      leftTablePause: false,
      leftTableTop: 0,
      finished: false,
      loadmoreLoading: false,

      loading: false,

    };
  },
  async mounted() {
    console.log(this.getWeekDates('2024-6-1'))
    this.role = localStorage.getItem("role") * 1;
    let res = await this.$api({
          method: "get",
          url: "/api/inspect/data/get/years",
        });
        if (res.code == 0) {
          this.yearList = res.data.year_list.reverse()
          this.currentYear = this.yearList[0]
        }
    // this.isSmallScreen = document.body.clientWidth < 1440
    this.$nextTick(()=>{
        setTimeout(()=>{
          this.isSmallScreen = this.$refs.myCharts.offsetWidth  < 1540
        },10)
    })
    window.onresize = () => {
      return (() => {
        this.changeMenu()
      })()
    }

    this.getYear()

    this.$refs['l3'].addEventListener('scroll', this.loadmore)
 
  },
  beforeDestroy() {
    clearInterval(this.leftTableTimer)
    this.$refs['l3'].removeEventListener('scroll', this.loadmore)
  },
  methods: {
    getYear(){
      let month = new Date().getMonth() + 1
      let date = new Date().getDate()
      this.today = new Date(this.currentYear+'-'+month+'-'+date)
      this.weekAgo = new Date(Math.max(this.today.getTime() - (3600 * 24 * 6 * 1000), new Date(this.currentYear + '-' + 1+'-'+1).getTime()))
      this.week7Ago = new Date(Math.max(this.today.getTime() - (3600 * 24 * 6 * 1000 *7), new Date(this.currentYear + '-' + 1+'-'+1).getTime()))
      this.month7Ago = month < 7 ? new Date(this.currentYear + '-' + 1+'-'+1) :
                       new Date(this.currentYear + '-' + (month-6)+'-'+date) 
      this.datetime = {
        1:[this.weekAgo, this.today],
        2:[this.week7Ago, this.today],
        3:[this.month7Ago, this.today]
      }
      this.getData()
    },
    async getData(){
      this.loading = true
      await this.init();
      await this.initRight1();
      await this.initRight2();
      await this.initLeft1();
      await this.initLeft2();
      await this.leftTable();
      await this.getSeven()
      await this.getSevenTime()
      await this.getSevenRate()
      this.$nextTick(() => {
        this.mapChart();
      })
      this.loading = false
    },
    async loadmore() {
      let box = document.getElementById('l3');
      let content = this.$refs['realTime'];
      var exp_height = box.clientHeight;
      var clientheight = content.clientHeight;
      var scrollTop = box.scrollTop;
      console.log(clientheight, scrollTop, exp_height)
      if (this.loadmoreLoading) {
        return;
      }
      if (this.finished) {
        return;
      }
      if (exp_height + scrollTop > clientheight - 10) {
        this.loadmoreLoading = true;
        this.leftTablePage += 1;
        let res = await this.$api({
          method: "get",
          url: "/api/inspect/data/real/time",
          data: {
            year: this.currentYear,
            inspect_type: this.activeName,
            page: this.leftTablePage,
            limit: 10
          }
        });
        if (res.code == 0) {
          if (res.data.real_time.length < 10) {
            this.finished = true;
          }
          this.leftTableData = this.leftTableData.concat(res.data.real_time);
        }
        this.loadmoreLoading = false;
      } else {
        this.loadmoreLoading = false;
        // console.log('no出现了')
      }
    },
    async leftTable() {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/real/time",
        data: {
          page: this.leftTablePage,
          year: this.currentYear,
          inspect_type: this.activeName,
          limit: 20
        }
      });
      if (res.code == 0) {
        res.data.real_time.forEach((item,index)=>{
          item.myIndex = res.data.real_time.length - index
        })
        this.leftTableData = res.data.real_time
        this.leftTableTop = 0
        this.leftTablePause = false
        if(this.leftTableData.length<11){
          clearInterval(this.leftTableTimer)
        }else{
          let el = document.getElementsByClassName('news')[0].getElementsByClassName('el-table__body-wrapper')[0]
          el.addEventListener('mouseenter', ()=> {
              this.leftTablePause = true
          });
          
          el.addEventListener('mouseleave', () =>{
              this.leftTablePause = false
          });

          this.leftTableTimer = setInterval(()=>{
            if(!this.leftTablePause){
              this.leftTableTop+=1
              el.scrollTo({
                top: this.leftTableTop * 48 ,
                behavior: "smooth"
              })
              if(this.leftTableTop == this.leftTableData.length - 10){
                this.leftTableTop = -1
              }
            }
            if(this.leftTableData.length<11){
              clearInterval(this.leftTableTimer)
            }
          },2000)
        }
        // for(let i = 0; i < res.data.real_time.length; i+=10){
        //   this.leftTableData.push(res.data.real_time.slice(i, i+10))
        // }
        // this.leftTableData = res.data.real_time;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },

    checkTime(val) {
      if (val < 10) {
        return "0" + val
      } else {
        return val
      }
    },

    async init() {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/index",
        data:{
          inspect_type: this.activeName,
          year: this.currentYear,
          data_type: this.totalRadio,
          city_type: this.levelSelect
        }
      });
      if (res.code == 0) {
        this.centerData = res.data;
        this.$nextTick(()=>{
          this.centerChart2()
        })
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async initRight2() {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/unqualified",
        data:{
          inspect_type: this.activeName,
          year: this.currentYear,
        }
      });
      if (res.code == 0) {
        this.rightData2 = res.data;
        this.questionRandList = this.rightData2.object_list.slice(0,this.questionRandSize)
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    getDateFormat(time) {
      let d = new Date(time);
      return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    },
    async initLeft1() {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/trend/analysis",
        data: {
          inspect_type: this.activeName,
          year: this.currentYear,
          type: this.r1tab,
          start_date: this.getDateFormat(this.datetime[this.r1tab][0]),
          end_date: this.getDateFormat(this.datetime[this.r1tab][1])
        }
      });
      if (res.code == 0) {
        if(this.r1tab == 2){
          console.log(res.data)
          res.data.data.forEach((item,index)=>{
            if(index==0){
              item.weekDate = item.date +'-'+this.getWeekDates(item.date).end.replace(/-/g,'/')
            }else if(index==res.data.data.length-1){
              item.weekDate = this.getWeekDates(item.date).start.replace(/-/g,'/') + '-' + item.date.replace(/-/g,'/')
            }else{
              item.weekDate = this.getWeekDates(item.date).start.replace(/-/g,'/')+'-'+this.getWeekDates(item.date).end.replace(/-/g,'/')
            }
          })
        }
        this.leftData1 = res.data;
        this.leftChart1();
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    getWeekDates (date) {
      // 获取当前日期
      var today = new Date(date);
      // 获取当前是星期几
      var day = today.getDay();
      if (day == 0) {
        // 计算本周第一天的日期
        var startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day - 6);
        // 计算本周最后一天的日期
        var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day);
      } else {
        var startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day + 1);
        var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day + 7);
      }
      // 返回本周的日期范围
      return { start: this.getDateFormat(startDate), end: this.getDateFormat(endDate) };
    },
    async initLeft2() {
      this.regionPage = 1
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/province/analysis",
        data:{
          inspect_type: this.activeName,
          year: this.currentYear,
          city_type: this.regionSelect
        }
      });
      if (res.code == 0) {
        this.leftData2 = res.data;
        let regionList = this.leftData2.province_list;
        for (const iterator of regionList) {
          iterator.name = iterator.name
          iterator.value = iterator.total;
          iterator.rate = iterator.org_ratio
        }
        this.regionList = regionList
        this.regionTable = this.regionList.slice(0,5)
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    handleCurrentChange(val){
      this.regionPage = val
      console.log((this.regionPage - 1) * 5)
      this.regionTable = this.regionList.slice((this.regionPage - 1) * 5, this.regionPage * 5)
    },
    async initRight1() {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/object/analysis",
        data:{
          inspect_type: this.activeName,
          year: this.currentYear,
          city_type: this.sevenSelect
        }
      });
      if (res.code == 0) {
        this.rightData1 = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    handleClick() {
      this.getData()
    },
    async mapChart() {
      let data1 = [];
      let pd = this.centerData.province_data;
      if(this.role == 1 || this.role == 2){
        for (const iterator of this.centerData.province_data) {
          iterator.name = proviceMap[iterator.code]
        }
      }
      
      data1 = this.centerData.province_data.map(e => {
        return {
          name: e.name,
          value: e.org_count * 1
        }
      })
      this.map = this.$echarts.init(document.getElementById('map'));
      let mapJson = china
      let mapName = 'china'
      if(this.role == 3 || this.role == 6){
        mapName = 'myMap'
        let code = localStorage.getItem('code')
        let res = await fetch(
          `https://tuoyufuwu.obs.cn-north-4.myhuaweicloud.com/common/geoJson/${code}.json`
        );
        mapJson = await res.json();
      }
      
      this.$echarts.registerMap(mapName, mapJson, {});
      let option = {
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          renderMode: 'html',
          transitionDuration: 0.2,
          className: 'echarts-tooltip',
          formatter: (params, ticket, callback) =>{
            let html = ''
            if(this.activeName == 0){
              html = `<p style="font-weight: bold;line-height: 2">${params.name}</p><p>托育机构数量：${pd[params.dataIndex].org_count}</p><p>已自评托育机构数量：${pd[params.dataIndex].inspect_count}</p><p>自评完成率：${pd[params.dataIndex].inspect_ratio}</p><p>监管他评机构数：${pd[params.dataIndex].inspect_count_ta}</p>`
            }else if(this.activeName == 1){
              html = `<p style="font-weight: bold;line-height: 2">${params.name}</p><p>托育机构数量：${pd[params.dataIndex].org_count}</p><p>已自评托育机构数量：${pd[params.dataIndex].inspect_count}</p><p>自评完成率：${pd[params.dataIndex].inspect_ratio}</p>`
            }else if(this.activeName == 2){
              html = `<p style="font-weight: bold;line-height: 2">${params.name}</p><p>托育机构数量：${pd[params.dataIndex].org_count}</p><p>市级他评机构数：${pd[params.dataIndex].shi_count}</p><p>省级他评机构数：${pd[params.dataIndex].sheng_count}</p><p>国家督评机构数：${pd[params.dataIndex].guojia_count}</p>`
            }
            return (html);
          },
          backgroundColor: 'rgba(0,0,0,0.7)',
          borderColor: 'rgba(50,50,50,0)',
          textStyle: {
            color: '#fff',
          }
        },
        geo: {
          id: 1,
          zoom: 1.2,
          roam: false,
          scaleLimit: {
            min: 1.2,
            max: 1.2,
          },
          map: mapName,
          regions: this.centerData.province_data.map((area) => {
            return {
              name: area.name,
              itemStyle: {
                areaColor: area.org_count > 0 ? '#cce7fe' : '#fff',
              },
            }
          }),
        },
        visualMap: {
          show: false,
          left: 'right',
          min: 0,
          max: 50,
          inRange: {
            // color: [
            //   '#fff',
            //   '#eef8ff',
            //   '#cce7fe',
            //   '#80c1fc',
            //   '#62a2ed',
            // ],
            color: [
              '#fff',
              '#cce7fe',
            ],
            colorAlpha: 0,
          },
          calculable: true
        },
        toolbox: {
          show: false,
          //orient: 'vertical',
          left: 'left',
          top: 'top',
          feature: {
            dataView: {
              readOnly: false
            },
            restore: {},
            saveAsImage: {}
          }
        },
        series: [{
          name: '',
          type: 'map',
          colorBy:'data',
          zoom: 1.2,
          roam: false,
          scaleLimit: {
            min: 1.2,
            max: 1.2,
          },
          label: {
            show: true,
            position: 'insideBottomRight',
            offset: [0, 0],
            fontSize: 11,
            verticalAlign: 'bottom'
          },
          map: mapName,
          emphasis: {
            label: {
              show: true
            }
          },
          data: data1,
          zlevel: 0
        }],
      };
      this.map.setOption(option);
    },
 
    centerChart2() {
      this.chartA = this.$echarts.init(document.getElementById('chart-levelA'));
      this.chartB = this.$echarts.init(document.getElementById('chart-levelB'));
      this.chartC = this.$echarts.init(document.getElementById('chart-levelC'));
      this.chartD= this.$echarts.init(document.getElementById('chart-levelD'));
      let arr = this.centerData.results_arr;
      let data = [{
          value: arr.results_4,
          name: '优秀'
        },
        {
          value: arr.results_3,
          name: '合格'
        },
        {
          value: arr.results_2,
          name: '不合格'
        },
        {
          value: arr.results_1,
          name: '需要重大改进'
        }
      ];
      let t = arr.results_1 + arr.results_2 + arr.results_3 + arr.results_4;
      this.echartPieTotal = t
      let max = Math.max(arr.results_1, arr.results_2, arr.results_3, arr.results_4)
      let index = [arr.results_4, arr.results_3, arr.results_2, arr.results_1].indexOf(max);
      this.selectValue1 = max ? ((max / t) * 10000 / 100).toFixed(1) : 0
      this.selectText1 = data[index].name
      let option = {
        color: ['#1890FF', '#F0F2F5'],
       
        series: [{
          type: 'pie',
          radius: ['75%', '90%'],
          // selectedMode: 'single',
          avoidLabelOverlap: false,
          cursor: 'pointer',
          label: {
            show: false,
            position: 'center',
          },
          emphasis:{
            disabled: true,
          },
          selectedOffset: 2,
          select: {
            label: {
              show: false,
            },
          },
          
          labelLine: {
            show: false
          },
          colorBy: 'data',
          data: [{
            value: arr.results_4,
            name: 'A级'
          },
          {
            value: t ? t - arr.results_4 : 1,
            name: '其他'
          }]
      
        }]
      };
      this.chartA.setOption(option)
      let optionB = JSON.parse(JSON.stringify(option))
      optionB.color[0] = '#2FC25B'
      optionB.series[0].data = [{
            value: arr.results_3,
            name: 'B级'
          },
          {
            value: t ? t - arr.results_3 : 1,
            name: '其他'
          }]
      this.chartB.setOption(optionB)
      let optionC = JSON.parse(JSON.stringify(option))
      optionC.color[0] = '#FACC14'
      optionC.series[0].data = [{
            value: arr.results_2,
            name: 'C级'
          },
          {
            value: t ? t - arr.results_2 : 1,
            name: '其他'
          }]
      this.chartC.setOption(optionC)
      let optionD = JSON.parse(JSON.stringify(option))
      optionD.color[0] = '#F2637B'
      optionD.series[0].data = [{
            value: arr.results_1,
            name: 'D级'
          },
          {
            value: t ? t - arr.results_1 : 1,
            name: '其他'
          }]
      this.chartD.setOption(optionD)
    },
    async getSeven(){
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/object/analysis/score",
        data:{
          inspect_type: this.activeName,
          year: this.currentYear,
          city_type: this.sevenSelect
        }
      });
      if (res.code == 0) {
        this.sevenData = res.data.object_list.slice(0,7);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async getSevenTime(){
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/object/analysis/time",
        data:{
          inspect_type: this.activeName,
          year: this.currentYear,
          city_type: this.timeSelect
        }
      });
      if (res.code == 0) {
        this.sevenTimeData = res.data.object_list.slice(0,7);
        
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async getSevenRate(){
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/object/analysis/average",
        data:{
          inspect_type: this.activeName,
          year: this.currentYear,
          city_type: this.rateSelect
        }
      });
      if (res.code == 0) {
        this.sevenRateData = res.data.object_list.slice(0,7);
        this.$nextTick(()=>{
          this.rightChart2()
        })
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    rightChart2() {
      this.r2 = this.$echarts.init(document.getElementById('r4'));
      console.log('r2',this.r2)
      let arr = this.sevenRateData;
      for (const iterator of arr) {
        iterator.value = iterator.total_time_ratio * 1;
      }
      let color = ['#1890FF', '#5AD8A6']
      let option = {
        color,
        grid: {
          left: 35,
          bottom: 40,
          top: 50,
          right: 0
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          },
          showDelay: 0,
          renderMode: 'html',
          transitionDuration: 0.2,
          className: 'echarts-tooltip',
          backgroundColor: 'rgba(0,0,0,0.7)',
          borderColor: 'rgba(50,50,50,0)',
          textStyle: {
            color: '#fff',
          },
           formatter: function (params, ticket, callback) {
            // console.log(params)
              let name = `${params[0].name.replace('\n','')}`
              let html = ''
              params.forEach(item=>{
                html+=`<p class="echartP"><span style="background:${color[item.seriesIndex]}"></span>${item.seriesName}：${item.value}%</p>`
              })
              return name+html;
          }
        },
        legend: {
          data: ['得分比重', '分值比重']
        },
        xAxis: [{
          type: 'category',
          data: arr.map(e => {
            return '' + e.name.slice(0, 2) + '\n' + (e.name.slice(2, 4)) + ''
          }),
          nameTextStyle: {
            width: 4
          },
          axisPointer: {
            type: 'shadow'
          }
        }],
        yAxis: [{
          type: 'value',
          name: '',
          min: 0,
          max: 25,
          interval: 5,
          axisLabel: {
            formatter: '{value}%'
          }
        }],
        series: [{
            left: 10,
            name: '得分比重',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + '%';
              }
            },
            data: arr.map(e => {
              return e.inspect_score_ratio.replace('%', '')
            })
          },
          {
            left: 10,
            name: '分值比重',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + '%';
              }
            },
            data: arr.map(e => {
              return e.object_score_ratio.replace('%', '')
            })
          }
        ]
      };
      option && this.r2.setOption(option);
    },
    leftChart1() {
      let list = this.leftData1.data.map(item=>{
        let v= {
          ...item,
          date: item.date,
          value: this.activeName == 2 ? parseFloat(item.inspect_num_shi) + parseFloat(item.inspect_num_sheng) + parseFloat(item.inspect_num_guojia) : item.inspect_num
        }
        return v
      });
      this.l1 = this.$echarts.init(document.getElementById('chart1'));
     
      let option = {
        grid: {
          left: 30,
          bottom: 66,
          top: 30
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/>{a}：{c}',
          showDelay: 0,
          renderMode: 'html',
          transitionDuration: 0.2,
          className: 'echarts-tooltip',
          backgroundColor: 'rgba(0,0,0,0.7)',
          borderColor: 'rgba(50,50,50,0)',
          textStyle: {
            color: '#fff',
          },
          formatter: (params)=> {
            let name = ''
            // console.log(params)
            let date = params.data.date.replace(/-/g, '/')+' '
            let data = params.data
            if(this.activeName !=2 ){
              name = this.r1tab == 3 ? `${date}提交自评次数 ${data.value}` : (this.r1tab == 2 ? `${params.data.weekDate} 提交自评次数 ${data.value}` : `${date}提交自评次数 ${data.value}`)
            }else{
              if(this.r1tab == 1){
                name = `${date}市级他评次数 ${data.inspect_num_shi}<br/>${date}省级他评次数 ${data.inspect_num_sheng}<br/>${date}国家督评次数 ${data.inspect_num_guojia}`
              }else if(this.r1tab == 2){
                name = `${params.data.weekDate} 市级他评次数 ${data.inspect_num_shi}<br/>${params.data.weekDate} 省级他评次数 ${data.inspect_num_sheng}<br/>${params.data.weekDate} 国家督评次数 ${data.inspect_num_guojia}`
              }else if(this.r1tab == 3){
                name = `${date}市级他评次数 ${data.inspect_num_shi}<br/>${date}省级他评次数 ${data.inspect_num_sheng}<br/>${date}国家督评次数 ${data.inspect_num_guojia}`
              }
            }
            return name
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: list.map(e => {
            return e.date.slice(5, 10) + (this.r1tab == 3 ? '月' : '')
          })
        },
        yAxis: {
          type: 'value',
          minInterval: 1
        },
        series: [{
          // name: name,
          data: list,
          type: 'line',
          itemStyle:{
            color: '#1890FF'
          }
        }]
      };
      option && this.l1.setOption(option);
    },
    questionRandMore(){
      if(this.questionFinished) return
      this.questionRandPage++
      this.questionRandList.push(...this.rightData2.object_list.slice((this.questionRandPage - 1) * this.questionRandSize, this.questionRandPage * this.questionRandSize))
      if(this.questionRandList.length == this.rightData2.object_list.length){
        this.questionFinished = true
      }
    },
    changeMenu(){
      this.$nextTick(()=>{
        this.isSmallScreen = this.$refs.myCharts.offsetWidth  < 1540
        setTimeout(()=>{
          this.l1.resize();
          this.r2.resize();
          this.chartA.resize();
          this.chartB.resize();
          this.chartC.resize();
          this.chartD.resize();
          this.map.resize()
        },1)
      })
    },
  },
  components: {
    // HelloWorld
  },
};
</script>

<style lang="scss" scoped>
::v-deep .echartP{
  display: flex;
  align-items: center;
  span{
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
}
// .realTable{
//   ::v-deep thead{
//     display: none;
//   }
// }
// .realTableHeader{
//   ::v-deep thbody{
//     display: none;
//   }
// }
.news{
  ::v-deep .el-table--scrollable-y .el-table__body-wrapper{
    overflow-y: hidden;
  }
}
.ellispe{
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;  
}
.header {
  width: 100%;
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #E8E8E8;
  ::v-deep .el-tabs__header{
    margin-bottom: 0 !important;
    padding-top: 14px;
    padding-left: 24px;
    .el-tabs__nav-wrap::after{
      height: 1px;
    }
  }
  .select{
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    font-size: 14px;
    color: rgba(0,0,0,0.65);
    ::v-deep .el-input__inner{
      height: 32px;
      line-height: 32px;
    }
    ::v-deep .el-input__icon{
      line-height: 32px;
    }
  }
}
.charts{
  height: calc(100vh - 60px - 40px);
  overflow: hidden;
}
h4{
  color: rgba(0,0,0,0.85);
  font-size: 16px;
  line-height: 56px;
  display: block;
}
.main {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  min-width: 1124px;
  width: 100%;
  .content{
    width: 100%;
    padding: 24px 24px 0;
    overflow-y: auto;
    font-size: 14px;
    .margin-bottom{
      margin-bottom: 24px;
    }
    .content-chart{
      display: flex;
      >div{
        flex-shrink: 0;
        width: calc( (100% - 24px) *0.5);

        &:nth-of-type(2){
          margin-left: 24px;
        }
      }
      
      .total-data{
        padding: 32px 0 0;
        height: 248px;
        position: relative;
        text-align: left;
        .radio{
          position: absolute;
          right: 10px;
          top: 8px;
          ::v-deep .el-radio-button--mini .el-radio-button__inner{
            padding: 7px 10px;
          }
        }
        >div{
          height: calc(100% - 32px);
        }
        p{
          color: rgba(0,0,0,0.45);
          width: 50%;
          padding-left: 24px;
          height: 50%;
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          span{
            color: rgba(0,0,0,0.85);
            font-size: 30px;
            padding-top: 4px;
          }
        }
      }
      
      .chart-level{
        display: flex;
        height: calc(100% - 56px);
        color: rgba(0,0,0,0.65);
        >div{
          flex: 1;
          width: calc( (100% - 48px) / 4);
          height: 100%;
          position: relative;
          p.label{
            position: absolute;
            left: 50%;
            top: calc((100% - 30px)*0.5);
            transform: translate(-50%,-50%);
            color: rgba(0,0,0,0.45);
            text-align: center;
            span{
              display: block;
              color: rgba(0,0,0,0.85);
              font-size: 24px;
            }
          }
          >div{
            height: calc(100% - 30px);
            width: 100%;
          }
        }
      }
    }
    .chart-item{
        background-color: #fff;
        padding: 0 24px;
        border-radius: 4px;
        position: relative;
        .select{
          position: absolute;
          right: 24px;
          top: 13px;
          width: 100px;
        }
      }
    .today-data{
        display: flex;
        flex-direction: column;
        padding: 0;
        h4{
          padding-left: 24px;
        }
        .number{
          color: rgba(0,0,0,0.45);
          display: flex;
          flex: 1;
          align-items: center;
          p{
            flex: 1;
            padding-left: 24px;
          }
          span{
            color: rgba(0,0,0,0.85);
            font-size: 30px;
            display: flex;
            padding-top: 4px;
            line-height: 38px;
            align-items: center;
            i{
              font-size: 16px;
            }
          }
          
        }
        .rate{
          margin: 29px 24px 25px;
          height: 8px;
          border-radius: 1px;
          background-color: #F0F2F5;
          position: relative;
          overflow: hidden;
          span{
            display: block;
            height: 100%;
            background-color: #5AD8A6;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        .average-number{
          margin: 0 24px;
          height: 41px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid #E8E8E8;
          color: rgba(0,0,0,0.65);
          span{
            color: rgba(0,0,0,0.85);
          }
        }
      }
    #map{
      height: 720px;
    }
    .intro{
      height: 248px;
      .intro-list{
        display: flex;
        padding-top: 24px;
        .intro-item{
          flex: 1;
          border-radius: 4px;
          padding: 16px;
          .intro-title{
            display: flex;
            align-items: center;
            color: rgba(0,0,0,0.85);
            margin-bottom: 12px;
            span{
              width: 40px;
              height: 40px;
              display: flex;
              border-radius: 50%;
              background-color: #fff;
              align-items: center;
              justify-content: center;
              margin-right: 8px;
              img{
                width: 24px;
              }
            }
          }
          .intro-data{
            display: flex;
            p{
              flex: 1;
              color: rgba(0,0,0,0.45);
              line-height: 22px;
              b{
                color: rgba(0,0,0,0.85);
                font-size: 24px;
                line-height: 38px;
              }
              span{
                color: rgba(0,0,0,0.65);
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .region{
      position: relative;
      height: 360px;
      ::v-deep .el-radio-group{
        position: absolute;
        right: 24px;
        top: 10px;
        .el-radio-button__orig-radio:checked+.el-radio-button__inner{
          background-color: #fff;
          color: #409EFF;
        }
      }
      .region-tu{
        display: flex;
        align-items: center;
        margin-top: 16px;
        height: calc(100% - 16px - 56px - 24px);
        >p{
          flex: 1;
          height: 100%;
          margin-right: 36px;
          span{
            width: 100%;
            display: block;
          }
        }
        ul{
          flex: 1.2;
          li{
            margin: 16px 0;
            display: flex;
            align-items: center;
            color: rgba(0,0,0,0.65);
            line-height: 22px;
            span{
              display: block;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-right: 7px;
            }
            p.region-rate{
              color: rgba(0,0,0,0.45);
              flex: 1;
              margin-left: 8px;
              padding-left: 8px;
              border-left: 1px solid #D9D9D9;
            }
          }
        }
        span.regionBg0{
          background-color: #3BA0FF;
        }
        span.regionBg1{
          background-color: #36CBCB;
        }
        span.regionBg2{
          background-color: #5D7092;
        }
        span.regionBg3{
          background-color: #F6BD16;
        }
        span.regionBg4{
          background-color: #E86452;
        }
        span.regionBg5{
          background-color: #6DC8EC;
        }
      }
      .region-table{
        ::v-deep .el-table {
          margin-bottom: 6px;
          th{
            background-color: #FAFAFA;
          }
          .el-table__cell{
            padding: 8px 0;
          }
        }
      }
    }
    .trend{
      display: flex;
      flex-direction: column;
      .top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        ::v-deep .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner{
          width:200px
        }
        ::v-deep .el-radio-button__inner{
          border: 0;
          padding: 7px 10px;
        }
        ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner{
          background-color: #fff;
          color: #409EFF;
          box-shadow: none
        }
      }
      #chart1{
        flex: 1;
        height: 100%;
      }
    }
    .news{
      height: 608px;
      display: flex;
      flex-direction: column;
      span{
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 8px;
      }
      ::v-deep .el-table{
        margin-top: 6px;
        flex: none;
        th{
          background-color: #FAFAFA;
        }
      }
      .more{
        cursor: pointer;
        text-align: center;
        padding-top: 6px;
      }
    }
    .seven-data{
      font-size: 14px;
      color: rgba(0,0,0,0.85);
      height: 400px;
      ul{
        display: flex;
        padding-top: 12px;
        height: calc(100% - 56px - 24px);
        li{
          flex: 1;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
          width: 100%;
          padding-bottom: 20px;
          img{
            margin-bottom: 12px;
            width: 48px;
          }
          p{
            text-align: center
          }
          p.seven-value{
            font-size: 20px;
          }
          p.seven-name{
            border-bottom: 1px solid #E8E8E8;
            padding-bottom: 24px;
            margin-bottom: 24px;
            width: 100%;
          }
          span{
            color: #8C8C8C;
            font-size: 14px;
            display: block;
            margin-bottom: 5px;
          }
        }
      }
    }
    .seven-time{
      ul{
        li{
          display: flex;
          height: 38px;
          line-height: 38px;
          color: #595959;
          padding: 0 16px;
          border-bottom: 1px solid #E9E9E9;
          &:first-of-type{
            background-color: #FAFAFA;
            color: rgba(0,0,0,0.65);
          }
          &:not(:first-of-type){
            p:first-of-type{
              color: #1890FF
            }
          }
          p:first-of-type{
            min-width: 100px;
          }
          p:nth-of-type(2){
            flex: 1;
            text-align: center;
          }
          p:nth-of-type(3){
            width: 80px;
            text-align: center;
          }
        }

      }
    }
    .seven-rate{
      #r4{
        height: calc(100% - 56px)
      }
    }
    .question-rand{
      padding-bottom: 24px;
      .item{
        display: flex;
        padding-top: 17px;
        color: rgba(0,0,0,0.65);
        
        span{
          display: block;
          flex-shrink: 0;
          width: 20px;
          height: 20px;
          margin-right: 24px;
          font-size: 12px;
          background: #F0F2F5;
          border-radius: 50%;
          text-align: center;
          line-height: 20px;
        }
        &:nth-of-type(-n+3) span{
          background: #1890FF;
          color: #fff;
        }
      }
      .more{
        cursor: pointer;
        margin: 20px auto;
        width: 153px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #D9D9D9;
        text-align: center;
        line-height: 32px;
        color: rgba(0,0,0,0.65);
      }
    }
  }
}
.bigScreen{
  .small{
    display: none;
  }
  .big{
    display: block;
  }
}
.smallScreen{
  .small{
    display: block;
  }
  .big{
    display: none;
  }
  .news{
    height: auto;
    max-height: 608px;
    min-height: 400px;
  }
}
.width100{
  display: block !important;
  >div{
    width: 100% !important;
    margin: 0 0 24px !important;
  }
}
@media screen and (max-width: 1900px) {
  
}

@media screen and (min-width: 1900px) {
  
}
</style>

<style lang="scss">
.el-range-editor--mini .el-range__close-icon {
  display: none;
}

.echarts-tooltip {
  text-align: left;
}
.charts{
  position: relative;
}
</style>
